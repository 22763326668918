import { Chip} from "@material-ui/core";
import { FunctionComponent } from "react";
import { ShiftType } from "../../../../Types";
import ShiftChipLabel from "./ShiftChipLabel";
import { unSelectShift, selectShift } from "../../../../../Scripts/myFirebase";

interface ShiftChipWithFlyoutProps {
    shift: ShiftType
}
 
const ShiftChipWithFlyout: FunctionComponent<ShiftChipWithFlyoutProps> = (props) => {

    const colors = {
        "Pending": "lemonchiffon",
        "Accepted": "palegreen",
        "In": "palegreen",
        "Out": "palegreen",
        "Rejected": "lightsalmon",
        "New": "lightgrey",
        "Selected": "lightcyan"
    }

    const handleClick = () => {
        
        if (props.shift.Status === "New" || props.shift.Status === "Rejected") {
            selectShift(props.shift.ID)
        } else if (props.shift.Status === "Selected") {
            unSelectShift(props.shift.ID)
        } 
    }

    return ( 
            <Chip 
                style={{ 
                    textAlign: "center", 
                    width: 225, 
                    height: 60, 
                    backgroundColor: `${colors[props.shift.Status]}` 
                }} 
                label={<ShiftChipLabel shift={props.shift} />} 
                onClick={(props.shift.Status === "New" || props.shift.Status === "Selected" || props.shift.Status === "Rejected")?handleClick:null}
            />
     );
}
 
export default ShiftChipWithFlyout;