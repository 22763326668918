import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { auth } from "../firebase";
import { theme } from "../Styles/theme";
import logo from "../Resources/hadco-staffing-solutions-logo.png";
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  button: {
    fontSize: 20,
  }
}));



export default function HADCOAppBarAuth() {
  const classes = useStyles();
  const phoneScreen = useMediaQuery('(max-width:720px)');

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{ backgroundColor: theme.palette.secondary.light }}
      >
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <img
              src={logo}
              alt="logo"
              style={{ 
                marginTop: "10px" ,
                height: phoneScreen ? "56px" : "161px"
              }}
            />
          </Typography>
          <Button color="primary" className={classes.button} onClick={async () => await auth.signOut()}>
            Sign Out
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}
