import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { auth } from "../firebase";
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import firebase from "../firebase";


const useStyles = makeStyles((theme) => ({
  grid: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: "300px",
    margin: "auto",
    marginTop: "10%",
  },
  form: {},
  textField: {
    margin: "15px",
  },
  passwordPaper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: "400px",
    margin: "auto",
    marginTop: "10%",
  },
  passwordTextField: {
    margin: "15px",
    width: "350px"
  },
}));

export default function SignIn() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = (event) => {
    event.preventDefault();
    setOpen(true)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const { email, password } = event.target.elements;
      await auth.signInWithEmailAndPassword(email.value, password.value);
    } catch (error) {
      alert("ERROR: " + error.message);
    }
  };

  const handlePasswordReset = async (event) => {
    event.preventDefault();

    const { resetEmail } = event.target.elements;
    firebase.auth().sendPasswordResetEmail(resetEmail.value)
    .then(() => {
      // Password reset email sent!
      // ..
    })
    .catch((error) => {
      console.error(error);
    });

    handleClose();
  }

  return (
    <>
      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Paper className={classes.paper}>
          <Grid
            className={classes.grid}
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <TextField
              id="email"
              name="email"
              label="Email Address"
              variant="outlined"
              className={classes.textField}
            />
            <TextField
              id="password"
              name="password"
              variant="outlined"
              label="Password"
              type="password"
              autoComplete="current-password"
              className={classes.textField}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.textField}
              type="submit"
            >
              Sign In
            </Button>
            <Link
              component="button"
              variant="body2"
              onClick={handleOpen}
            >
              Forgot Password
            </Link>
          </Grid>
        </Paper>
      </form>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={handlePasswordReset}
      >
        <Paper className={classes.passwordPaper}>
          <Grid
            className={classes.grid}
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Typography
              variant="h4"
              color="black"
            > 
              Reset Password
            </Typography>
            <Typography
              variant="subtitle2"
              color="black"
            > 
              If the email address is found, an email will be sent with a link to reset your password
            </Typography>
            <TextField
              id="resetEmail"
              name="resetEmail"
              label="Email Address"
              variant="outlined"
              className={classes.passwordTextField}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.textField}
              type="submit"
            >
              Submit
            </Button>
          </Grid>
        </Paper>
      </form>
      </Modal>
    </>
  );
}
