import TabsUser from "../Components/User/TabsUser";
import TabsAdmin from "../Components/Admin/TabsAdmin";
import { auth, firestore } from "../firebase";
import { useDocumentData } from "react-firebase-hooks/firestore"
import { User } from "../Components/Types";
import { CircularProgress } from "@material-ui/core";

const Tabs = () => {
  const userDocRef = firestore.collection("Users").doc(auth.currentUser.uid);
  const [user, loading, error] = useDocumentData<User>(userDocRef);

  return (
    <>
      {loading && 
        <CircularProgress/>
      }
      {error && 
        <div>
          <h3>Error</h3>
          {error}
        </div>
      }
      {!loading && !error && 
        (user.isAdmin ? <TabsAdmin /> : <TabsUser />)
      }
    </>
  );
};

export default Tabs;
