import { Button, Dialog, DialogActions, IconButton, TextField, Tooltip } from '@material-ui/core';
import { CreateOutlined, DeleteOutlineOutlined } from '@material-ui/icons';
import AddIcon from "@material-ui/icons/Add";
import {firestore} from '../../../../firebase';
import MaterialTable from 'material-table';
import moment from 'moment';
import { FunctionComponent, useEffect, useState } from 'react';
import { addPosition, disablePosition } from '../../../../Scripts/myFirebase';
import ConfirmModal from '../../../ConfirmModal';
import { JobType, PositionType, MUITableColumn } from '../../../Types';
import ShiftsContainer from "../ShiftsContainer"
import PositionForm from './PositionForm';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';

interface JobPositionDisplayProps {
  job: JobType
}

const JobPositionDisplay: FunctionComponent<JobPositionDisplayProps> = (props: JobPositionDisplayProps) => {

  const [positionTitle, setPositionTitle] = useState<string>('');
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [openPositionForm, setOpenPositionForm] = useState<boolean>(false);
  const [selectedPosition, setSelectedPosition] = useState<PositionType>();
  const [positions, loadingPositions, errorGettingPositions] = useCollectionData<PositionType>(
    firestore
    .collection("Positions")
    .where("JobId", "==", props.job.Id)
    .where("isActive", "==", true),
    {});



  const buildColumns = (): MUITableColumn[] => {
    let columnArray: MUITableColumn[] = [{
      title: 'Position',
      field: 'Title',
      cellStyle: {
        padding: ".5rem 1rem"
      }
    }]
    for (let i = 0; i < props.job.DaysNeeded; i++) {
      const shiftDate = moment(props.job.StartDate).add(i, 'days')
      columnArray.push({
        title: shiftDate.format('ddd M-DD'),
        field: '',
        render: (rowData: PositionType) => <ShiftsContainer key={`${props.job.Id}-${rowData.Id}`} Job={props.job} Position={rowData} ShiftDate={shiftDate.toDate()} />,
        cellStyle: {
          padding: ".5rem 1rem"
        }
      })
    }
    return columnArray;
  }


  function deletePosition(): void {
    disablePosition(selectedPosition.Id);
    setOpenConfirmModal(false);
  }

  function handleAddPosition():void {
    addPosition(props.job, positionTitle);
    setPositionTitle("");
  }

  const handleKeyPressAddPosition = (event) => {
    if (event.key === 'Enter') {
      handleAddPosition();
    }
  }

  return (
    <div>
      <MaterialTable
        title={props.job.JobName}
        data={positions}
        columns={buildColumns()}
        options={{
          paging: false,
          rowStyle: (rowData, index, level) => ({
            // backgroundColor: level % 2 ? "#EEE" : "#FFF",
          }),
          search: false,
        }}
        actions={[
          {
            icon: DeleteOutlineOutlined,
            tooltip: "Delete Position",
            onClick: (event, rowData: PositionType) => {
              setSelectedPosition(rowData);
              setOpenConfirmModal(true);
            },
          },
          {
            icon: CreateOutlined,
            tooltip: "Edit Position",
            onClick: (event, rowData: any) => {
              setSelectedPosition(rowData)
              setOpenPositionForm(true);
            },
          },
        ]}
      />
      <div style={{display: 'flex', padding: '2rem', gap: '1rem', flexDirection: 'row'}}>
        <TextField 
          id="positionTitle"
          label="New Position"
          variant="outlined"
          color="primary"
          value={positionTitle}
          onChange={(event)=> setPositionTitle(event.target.value)}
          onKeyPress={handleKeyPressAddPosition}
          style={{minWidth: '12rem'}}
        ></TextField>
        <Tooltip title="Add Position" placement="top">
          <IconButton color="secondary" aria-label="add a new position" >
            <AddIcon onClick={handleAddPosition} />
          </IconButton>
        </Tooltip>
      </div>

      {/* The confirmation modal to disable a position. */}
      <ConfirmModal
        open={openConfirmModal}
        title="Disable Position"
        description="Are you sure you want to disable this Position"
      >
        <DialogActions>
          {/* @ts-ignore */}
          <Button
            onClick={() => {
              setOpenConfirmModal(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          {/* @ts-ignore */}
          <Button onClick={deletePosition} color="secondary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </ConfirmModal>

      <Dialog
        onClose={() => setOpenPositionForm(false)}
        open={openPositionForm}
        fullWidth={true}
        maxWidth="xl"
      >
        <PositionForm position={selectedPosition} setOpenPositionForm={setOpenPositionForm}/>
      </Dialog>

    </div>);

}

export default JobPositionDisplay;
