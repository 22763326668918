import { useEffect, useState } from "react";
import MaterialTable from "material-table";
import firebase, {firestore} from '../../../firebase';
import {
  DeleteOutlineOutlined,
  CreateOutlined,
  Add,
} from "@material-ui/icons";
import ConfirmModal from "../../ConfirmModal";
import { DialogActions, Button, Dialog, } from "@material-ui/core";
import { disableJob } from "../../../Scripts/myFirebase";
import JobSetupStepper from "./JobSetupStepper";
import { JobType } from "../../Types";
import { v4 as uuidv4 } from "uuid";
import { convertPropertyToDate } from "../../../Scripts/utils";

const JobsTable = () => {
  //State Management
  const [openJobStepper, setOpenJobStepper] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [selectedJobId, setSelectedJobId] = useState<string>("");
  const [selectedJob, setSelectedJob] = useState<JobType>({
    Id: "",
    ClientName: "",
    JobName: "",
    DaysNeeded: 0,
    Uniform: "",
    StartDate: new Date(),
    EndDate: new Date(),
    ReportLocation: "",
    JobDescription: "",
    SpecialNotes: "",
    PayRate: 0,
    PayDate: new Date(),
    // NumberEmployees: 0,
    IsActive: true,
  });
  
  //This sets the IsActive job property to false
  const disableAJob = () => {
    try {
      disableJob(selectedJobId);
      setOpenConfirmModal(false);
    } catch (e) {
      console.log(e);
    }
  };

  const today = new Date();

  const [jobData, setJobData] = useState<JobType[]>();
  useEffect(()=>{
    const unsubscribe = firestore
    .collection("Jobs")
    .where("IsActive", "==", true)
    .where("StartDate", ">=", firebase.firestore.Timestamp.fromDate(new Date(today.setDate(today.getDate() - 35))))
    .orderBy("StartDate", "desc")
    .onSnapshot((querySnapshot) => {
      // setIsLoading(false)
      let x: JobType[] = querySnapshot.docs.map(function (doc) {
          let localDocObject = doc.data()
          convertPropertyToDate(localDocObject,'StartDate')
          convertPropertyToDate(localDocObject,'EndDate')
          convertPropertyToDate(localDocObject,'PayDate')
          let job =  localDocObject as JobType;
          return job;
        })
      setJobData(x)
    })
    return () => unsubscribe()
  },[])
  
  const columns: { title: string; field: string; render?:any }[] = [
    { title: "Client", field: "ClientName" },
    { title: "Job", field: "JobName" },
    { title: "Start", field: "StartDate",render :(rowData)=><span>{rowData.StartDate.toLocaleDateString()}</span> },
    { title: "End", field: "EndDate",render :(rowData)=><span>{rowData.EndDate.toLocaleDateString()}</span> },
  ];

  return (
    <>
      <MaterialTable
        title={""}
        columns={columns}
        data={jobData?.map((el: JobType) => {
          return {
            Id: el.Id,
            ClientName: el.ClientName,
            JobName: el.JobName,
            DaysNeeded: el.DaysNeeded,
            Uniform: el.Uniform,
            StartDate: el.StartDate,
            EndDate: el.EndDate,
            ReportLocation: el.ReportLocation,
            JobDescription: el.JobDescription,
            SpecialNotes: el.SpecialNotes,
            PayRate: el.PayRate,
            PayDate: el.PayDate,
            // NumberEmployees: el.NumberEmployees,
            IsActive: el.IsActive,
          };
        })}
        options={{
          paging: false,
          maxBodyHeight: "Calc(100VH - 225px)",
          rowStyle: (rowData) => ({
            backgroundColor: rowData.tableData.id % 2 ? "#EEE" : "#FFF",
          }),
        }}
        actions={[
          {
            icon: DeleteOutlineOutlined,
            tooltip: "Delete Job",
            onClick: (event, rowData: any) => {
              setSelectedJobId(rowData.Id);
              setOpenConfirmModal(true);
            },
          },
          {
            icon: CreateOutlined,
            tooltip: "Edit Job",
            onClick: (event, rowData: any) => {
              setSelectedJob(rowData);
              setOpenJobStepper(true);
            },
          },
          {
            icon: Add,
            tooltip: "Create Job",
            isFreeAction: true,
            // position: "toolbar",
            onClick: () => {
              setOpenJobStepper(true);
              //@ts-ignore
              setSelectedJob({ Id: uuidv4() });
            },
          },
        ]}
      />

      {/* This Dialog holds the Job Stepper which provides the workflow for HADCO Staffing Events(Jobs) */}
      <Dialog
        onClose={() => setOpenJobStepper(false)}
        open={openJobStepper}
        fullWidth={true}
        maxWidth="xl"
      >
        <JobSetupStepper job={selectedJob} setSelectedJob={setSelectedJob} setOpenJobStepper={setOpenJobStepper} />
      </Dialog>

      {/* The confirmation modal to disable a job. */}
      <ConfirmModal
        open={openConfirmModal}
        title="Disable Job"
        description="Are you sure you want to disable this Job"
      >
        <DialogActions>
          {/* @ts-ignore */}
          <Button
            onClick={() => {
              setOpenConfirmModal(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          {/* @ts-ignore */}
          <Button onClick={disableAJob} color="secondary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </ConfirmModal>
    </>
  );
};

export default JobsTable;
