import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import JobForm from "./JobForm";
import { updateJob } from "../../../Scripts/myFirebase";
import { getNumDays } from "../../../Scripts/utils";
import { JobType } from "../../Types";
import JobPositionDisplay from "./newPositions/JobPositionsDisplay";
import JobPositions from "./Positions/JobPositions";
import { Step, StepLabel, Stepper } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "1rem",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    buttonGroup: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "2rem",
    },
  })
);

function getSteps() {
  return ["Job Information", "Positions/Shifts", "Schedule Shifts"];
}

function getStepContent(step: number, job: JobType) {
  switch (step) {
    case 0:
      return <JobForm job={job} />;
    case 1:
      return <JobPositions job={job} />;
    case 2:
      return <JobPositionDisplay job={job} />;
    default:
      return "Unknown step";
  }
}

export default function JobSetupStepper({ job, setSelectedJob, setOpenJobStepper }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const steps = getSteps();

  //ToDo Update to use State like Position Form
  const handleJobSubmit = () => {
    try {
      const clientName = (
        document.getElementById("clientName") as HTMLInputElement
      ).value;
      const jobName = (document.getElementById("jobName") as HTMLInputElement)
        .value;
      const uniform = (document.getElementById("uniform") as HTMLInputElement)
        .value;
      const reportLocation = (
        document.getElementById("reportLocation") as HTMLInputElement
      ).value;
      const jobDescription = (
        document.getElementById("jobDescription") as HTMLInputElement
      ).value;
      const specialNotes = (
        document.getElementById("specialNotes") as HTMLInputElement
      ).value;
      const payRate = parseFloat(
        (document.getElementById("payRate") as HTMLInputElement).value
      );
      // const numberEmployees = parseInt(
      //   (document.getElementById("numberEmployees") as HTMLInputElement).value
      // );
      const payDate = new Date((document.getElementById("payDate") as HTMLInputElement).value);
      const startDateTime = new Date((document.getElementById("startDate") as HTMLInputElement).value);
      const endDateTime = new Date((document.getElementById("endDate") as HTMLInputElement).value);

      const tempJobObj: JobType = {
        Id: job.Id,
        ClientName: clientName,
        DaysNeeded: getNumDays(new Date(startDateTime), new Date(endDateTime)),
        EndDate: endDateTime,
        JobDescription: jobDescription,
        JobName: jobName,
        IsActive: true,
        // NumberEmployees: numberEmployees,
        PayDate: payDate,
        PayRate: payRate,
        ReportLocation: reportLocation,
        SpecialNotes: specialNotes,
        StartDate: startDateTime,
        Uniform: uniform,
      };
      updateJob(tempJobObj);
      setSelectedJob(tempJobObj);
    } catch (e) {
      console.log(e);
    }
  };
  const handleNext = () => {
    activeStep === 0 && handleJobSubmit();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = () => {
    setOpenJobStepper(false);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        <Typography className={classes.instructions}>
          {getStepContent(activeStep, job)}
        </Typography>
        <div className={classes.buttonGroup}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.button}
          >
            Back
          </Button>
          {
            activeStep === steps.length - 1 ?
              <Button
                variant="contained"
                color="primary"
                onClick={handleClose}
                className={classes.button}
              >
                Finish
              </Button> :
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                Next
              </Button>
          }
        </div>
      </div>
    </div>
  );
}
