import { TextField, FormGroup, Paper, Button } from "@material-ui/core";
import { PositionType } from "../../../Types";
import { useState } from "react";
import { updatePosition } from "../../../../Scripts/myFirebase"

interface PositionFormProps {
  position: PositionType
  setOpenPositionForm: any
}

const PositionForm = (props: PositionFormProps) => {
  const [Position, setPosition] = useState<PositionType>(props.position);

  const handleChange = (prop) => (event) => {
    setPosition({ ...Position, [prop]: event.target.value });
  };

  const handleClose = () => {
    props.setOpenPositionForm(false);
  }

  const handleUpdate = () => {
    updatePosition(Position);
    props.setOpenPositionForm(false);
  }

  return (
    <Paper
      style={{ padding: 30 }}
    >
      <FormGroup>
        <TextField
          id="title"
          label="Position Title"
          variant="outlined"
          color="primary"
          defaultValue={props.position.Title}
          onChange={handleChange('Title')}
        />
        <TextField
          id="payRate"
          label="Pay Rate"
          type="number"
          variant="outlined"
          color="primary"
          style={{ marginTop: ".5rem" }}
          defaultValue={props.position.PayRate}
          onChange={handleChange('PayRate')}
        />
        <TextField
          id="reportLocation"
          label="Report Location"
          variant="outlined"
          color="primary"
          style={{ marginTop: ".5rem" }}
          defaultValue={props.position.ReportLocation}
          onChange={handleChange('ReportLocation')}
        />
        {/* <TextField
          id="numberEmployees"
          label="Number of Employees"
          variant="outlined"
          type="number"
          color="primary"
          style={{ marginTop: ".5rem" }}
          defaultValue={props.position.EmployeesNeeded}
          onChange={handleChange('EmployeesNeeded')}
        /> */}
        <TextField
          id="specialNotes"
          label="Special Notes"
          variant="outlined"
          multiline={true}
          color="primary"
          style={{ marginTop: ".5rem" }}
          defaultValue={props.position.SpecialNotes}
          onChange={handleChange('SpecialNotes')}
        />
        <TextField
          id="uniform"
          label="Uniform"
          variant="outlined"
          multiline={true}
          color="primary"
          style={{ marginTop: ".5rem" }}
          defaultValue={props.position.Uniform}
          onChange={handleChange('Uniform')}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "2rem"
          }}
          
        >
          <Button
          color={"secondary"}
          onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
          color={"primary"}
          variant="contained"
          onClick={handleUpdate}
          >
            Update
          </Button>
        </div>
      </FormGroup>
    </Paper>
  );
};

export default PositionForm;
