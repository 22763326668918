import React from 'react';
import {makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { ShiftType } from '../../Types';
import { shiftAcceptShift, shiftRejectShift ,shiftClockIn, shiftClockOut } from '../../../Scripts/myFirebase';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 350,
      marginBottom: 15,
      height: "fit-content",
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },

  }),
);

export default function ShiftCard(shift: ShiftType) {
  const today = moment(new Date());
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [clockedIn, setClockedIn] = React.useState(shift.Status === "In" ? true : false);
  let bgColor = "white";

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleAccepted = () => {
    shiftAcceptShift(shift.ID);
  }

  const handleDecline = () => {
    shiftRejectShift(shift.ID);
  }

  const handleClockedIn = () => {
    setClockedIn(true);
    shiftClockIn(shift);
  }

  const handleClockedOut = () => {
    setClockedIn(false);
    shiftClockOut(shift);
  }

  
  
  let cardAction: {};
  switch (shift.Status) {  
    case "Pending":
      cardAction = 
        <div>
          <Button style={{marginLeft: 7}} variant="contained" color="primary" onClick={handleAccepted} >Accept</Button>
          <Button style={{marginLeft: 7}} variant="contained" color="secondary" onClick={handleDecline} >Decline</Button>
        </div>
      bgColor = "lemonchiffon";
      break;
    case "Accepted":
      cardAction = 
      <div>
        <IconButton aria-label="Clock In">
          <FormControlLabel
            value="top"
            control={<Switch
              checked={clockedIn}
              onChange={handleClockedIn}
              color="primary"
              name="ClockedIn"
              inputProps={{ 'aria-label': 'primary checkbox' }}
              />}
            label="Clock In"
            labelPlacement="top"
          />
        </IconButton>
        {moment(shift.ShiftDate) >= today.add(1, "days") && 
        <Button style={{marginLeft: 7}} variant="contained" color="secondary" onClick={handleDecline} >Decline</Button>
        }
      </div>
      break;
    case "In":
      cardAction = 
      <IconButton aria-label="Clock Out">
      <FormControlLabel
        value="top"
        control={<Switch
          checked={clockedIn}
          onChange={handleClockedOut}
          color="primary"
          name="ClockedIn"
          inputProps={{ 'aria-label': 'primary checkbox' }}
          />}
        label="Clock Out"
        labelPlacement="top"
      />
    </IconButton>
    break;
    case "Out":
      cardAction = <IconButton aria-label="Clock In">
      <FormControlLabel
        value="top"
        control={<Switch
          checked={clockedIn}
          onChange={handleClockedIn}
          color="primary"
          name="ClockedIn"
          inputProps={{ 'aria-label': 'primary checkbox' }}
          />}
        label="Clock In"
        labelPlacement="top"
      />
    </IconButton>
    break;
    default:
      console.log("Error in Switch Statement");
  }

  return (
    <Card className={classes.root} style={{backgroundColor: bgColor}}>
      <CardHeader
        title={shift.JobName}
        subheader={shift.PositionTitle}
        style={{
          paddingTop: 8,
          paddingBottom: 0 
        }}
      />
      <CardContent
        style={{
          paddingTop: 0 ,
          paddingBottom: 0
        }}
      >
        <Typography>{`${shift.ShiftDate.toLocaleDateString()} | ${shift.StartTime.toLocaleString('en-US', { hour:'numeric',minute:'numeric', hour12: true })} - ${shift.EndTime.toLocaleString('en-US', { hour:'numeric',minute:'numeric', hour12: true })}`}</Typography>
      </CardContent>
      <CardActions 
        disableSpacing
        style={{padding: 0}}
      >
          {cardAction}

        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="Details"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="h5" paragraph>Description:</Typography>
          <Typography paragraph>{shift.Description}</Typography>

          <Typography variant="h5" paragraph>Location:</Typography>
          <Typography paragraph>{shift.Location}</Typography>

          <Typography variant="h5" paragraph>Uniform:</Typography>
          <Typography paragraph>{shift.Uniform}</Typography>

          <Typography variant="h5" paragraph>Notes:</Typography>
          <Typography paragraph>{shift.SpecialNotes}</Typography>

          <Typography variant="h5" paragraph>Pay Rate:</Typography>
          <Typography paragraph>{shift.PayRate}</Typography>

        </CardContent>
      </Collapse>
    </Card>
  );
}
