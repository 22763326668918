import { PeriodWorkedType } from "../../Types";
import styled from "@emotion/styled";
import {convertPropertyToDate} from "../../../Scripts/utils"
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import moment from "moment";

interface TimePeriodProps {
    TimePeriodProp: PeriodWorkedType
  }

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  typography: {
    padding: '5px'
  }
})
)

const TimePeriod = (props: TimePeriodProps) => {
  const classes = useStyles();
  if (Object.prototype.toString.call(props.TimePeriodProp.clockIn) === "[object Object]") {
    convertPropertyToDate(props.TimePeriodProp, 'clockIn');
  }
  if (Object.prototype.toString.call(props.TimePeriodProp.clockOut) === "[object Object]") {
    convertPropertyToDate(props.TimePeriodProp, 'clockOut');
  }
    return (
      <PeriodDiv>
        <Typography
          className={classes.typography}
        >
          In: {props.TimePeriodProp.clockIn.toLocaleString('en-US', { hour:'numeric',minute:'numeric', hour12: true })}  
        </Typography>
        <Typography
          className={classes.typography}
        >
          Out: {props.TimePeriodProp.clockOut && props.TimePeriodProp.clockOut.toLocaleString('en-US', { hour:'numeric',minute:'numeric', hour12: true })}
        </Typography>
        <Typography
          style={{marginRight: '45px'}}
          className={classes.typography}
        >
          Hours: {props.TimePeriodProp.clockOut && moment(props.TimePeriodProp.clockOut).diff(moment(props.TimePeriodProp.clockIn), 'hours', true).toFixed(2)}
        </Typography>
      </PeriodDiv>
    )
};

const PeriodDiv = styled.div({
    display: 'flex',
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: '15px'
  });

export default TimePeriod;
