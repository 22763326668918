import { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { DeleteOutlineOutlined, CreateOutlined, Add, EmailOutlined } from "@material-ui/icons";
import ConfirmModal from "../../ConfirmModal";
import { DialogActions, Button, CircularProgress } from "@material-ui/core";
import {auth, firestore, functions} from "../../../firebase";
import UserForm from "./UserForm";
import { MUITableColumn, User } from "../../Types";

const UsersTable = () => {
  //Empty user to setSelectedUser with
  const emptyUser:User = {
    Name: "",
    Email: "",
    Phone: "",
    UID: "",
    isAdmin: false,
    isActive: true,
    FirstName: "",
    LastName: ""
  }

  //State management
  const [openUserModal, setOpenUserModal] = useState<boolean>(false);
  const [openConfirmModal, SetOpenConfirmModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [Employees, setEmployees] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User>(emptyUser);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //Disable a user
  const disableUser = functions.httpsCallable("disableUser");

  const handleUserDisable = async () => {
    setIsLoading(true);
    await disableUser({
      UID: selectedUser.UID,
    });
    setIsLoading(false);
    SetOpenConfirmModal(false);
  };

  //User table data 
  useEffect(() => {
    const unsubscribe = firestore
        .collection("Users")
        .where("isActive", "==", true)
        .onSnapshot((querySnapshot) => {
            let allEmployees: User[] = querySnapshot.docs.map((doc) => {
                return doc.data() as User
            })
            setEmployees(allEmployees)
        })
    return () => unsubscribe()
}, []);

  //Column data
  const columns: MUITableColumn[] = [
    { title: "First Name", field: "FirstName" },
    { title: "Last Name", field: "LastName" },
    { title: "Email", field: "Email" },
    { title: "Phone", field: "Phone"},
    { title: "Role", field: "isAdmin", render :(rowData)=> rowData.isAdmin ? "Admin" : "User" },
  ];

  return (
    <>
    {isLoading? <CircularProgress /> : null}
      <MaterialTable
        title={""}
        columns={columns}
        data={Employees}
        options={{
          paging: false,
          maxBodyHeight: "Calc(100VH - 225px)",
          rowStyle: (rowData) => ({
            backgroundColor: rowData.tableData.id % 2 ? "#EEE" : "#FFF",
          }),
        }}
        actions={[
          {
            icon: DeleteOutlineOutlined,
            tooltip: "Disable User",
            onClick: (event, rowData: any) => {
              setSelectedUser(rowData as User);
              SetOpenConfirmModal(true);
            },
          },
          {
            icon: CreateOutlined,
            tooltip: "Edit User",
            onClick: (event, rowData: any) => {
              setSelectedUser(rowData as User);
              setIsEdit(true)
              setOpenUserModal(true);
            },
          },
          {
            icon: EmailOutlined,
            tooltip: "Send Welcome Email",
            onClick: (event, rowData: any) => {
              console.log(rowData);
              auth.sendPasswordResetEmail(rowData.Email);
            },
          },
          {
            icon: Add,
            tooltip: "Create User",
            isFreeAction: true,
            onClick: () => {
              setIsEdit(false)
              setSelectedUser(emptyUser)
              setOpenUserModal(true)
            }
          },
        ]}
      />

      {/* Modal to disable a user */}
      <ConfirmModal
        title={`Delete User: ${selectedUser.Name}`}
        description={`Are you sure you want to delete the following User:
                      Name: ${selectedUser.Name}  
                      Email: ${selectedUser.Email}`}
        open={openConfirmModal}
      >
        <DialogActions>
          <Button
            onClick={() => {
              SetOpenConfirmModal(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleUserDisable} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </ConfirmModal>

      {/* Add and edit users */}
      <UserForm
        open={openUserModal}
        setOpen={setOpenUserModal}
        user={selectedUser}
        isEdit={isEdit}
        setUser={setSelectedUser}
        setLoading={setIsLoading}
      >
        <Button
          onClick={() => {
            setOpenUserModal(false);
          }}
        >
          Cancel
        </Button>
      </UserForm>
    </>
  );
};

export default UsersTable;
