import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { createUser, editUser } from "../../../Scripts/myFirebase";
import { User } from "../../Types";
import { formatPhoneNumber } from '../../../Scripts/utils';
import { useState } from "react";

interface UserFormProps {
  open: boolean,
  setOpen: any,
  children: any,
  user: User,
  setUser: any,
  isEdit: Boolean,
  setLoading: any
}

const UserForm = (props: UserFormProps) => {

  const [isEmailChange, setIsEmailChange] = useState <boolean>(false)

  const handleChange = (prop) => (event) => {
    if (prop === "isAdmin") {
      props.setUser({ ...props.user, [prop]: event.target.checked });
    } else if (prop === "Phone") {
      props.setUser({ ...props.user, [prop]: formatPhoneNumber(event.target.value) });
    } else if (prop === "Email") {
      props.setUser({ ...props.user, [prop]: event.target.value });
      setIsEmailChange(true);
    } else {
      props.setUser({ ...props.user, [prop]: event.target.value });
    }
  };

  const handleUserSubmit = async () => {
    try {
      if (props.isEdit) {
        editUser({...props.user, Name: props.user.FirstName + " " + props.user.LastName}, isEmailChange);
      }
      else {
        props.setLoading(true);
        await createUser({...props.user, Name: props.user.FirstName + " " + props.user.LastName});
        props.setLoading(false);
      }
      props.setOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog open={props.open}>
      <DialogTitle>Create a New User</DialogTitle>
      <DialogContent>
        <FormGroup>
          <TextField
            id="FirstName"
            label="First Name"
            variant="outlined"
            defaultValue={props.user.FirstName}
            color="primary"
            onChange={handleChange('FirstName')}
          />
          <TextField
            id="LastName"
            label="Last Name"
            variant="outlined"
            defaultValue={props.user.LastName}
            color="primary"
            onChange={handleChange('LastName')}
            style={{ marginTop: ".5rem" }}
          />
          <TextField
            id="userEmail"
            label="Email"
            variant="outlined"
            defaultValue={props.user.Email}
            color="primary"
            style={{ marginTop: ".5rem" }}
            onChange={handleChange('Email')}
          />
          <TextField
            id="userPhone"
            label="Phone"
            variant="outlined"
            value={props.user.Phone}
            color="primary"
            style={{ marginTop: ".5rem" }}
            onChange={handleChange('Phone')}
          />
          <FormControlLabel
            control={
              <Checkbox
                id="adminBox"
                defaultChecked={props.user.isAdmin}
                name="userAdmin"
                color="primary"
                onChange={handleChange('isAdmin')}
              />
            }
            label="Admin"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        {props.children}
        <Button color="primary" onClick={handleUserSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserForm;
