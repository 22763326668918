import moment from "moment";
import { FunctionComponent } from "react";
import { ShiftType } from "../../../../Types";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


interface ShiftChipLabelProps {
    shift: ShiftType
}
 
const ShiftChipWithFlyout: FunctionComponent<ShiftChipLabelProps> = (props) => {


    return ( 
        <div 
            style = {{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            }}
        >
            {
                props.shift.Status == "Selected" &&
                <CheckCircleIcon 
                    style = {{
                        padding: "10px"
                    }}            
                />
            }
            <div
                style = {{
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <p
                style={{margin: 0}}
                >
                    {moment(props.shift.StartTime).format("hh:mmA")} - {moment(props.shift.EndTime).format("hh:mmA")}
                </p> 
                <p
                style={{margin: 0}}
                >
                    {props.shift.EmployeeName}
                </p> 
            </div>
        </div>
 );
}

export default ShiftChipWithFlyout;