import {firestore} from '../../../../firebase';
import { FunctionComponent, useEffect, useState } from 'react'
import { convertPropertyToDate } from '../../../../Scripts/utils';
import { PositionType, ShiftType } from '../../../Types'
import ShiftsScheduleDisplay from './ShiftsScheduleDisplay';


interface JobShiftsDisplayProps {
    position: PositionType,
    ShiftDate: Date
}

const JobShiftsDisplay: FunctionComponent<JobShiftsDisplayProps> = (props: JobShiftsDisplayProps) => {

    const [Shifts, setShifts] = useState<ShiftType[]>([])

    useEffect(() => {
        const unsubscribe = firestore
            .collection("Shifts")
            .where("PositionID", "==", props.position.Id)
            .where("isActive", "==", true)
            .where("ShiftDate", "==", props.ShiftDate)
            .onSnapshot((querySnapshot) => {
                let AllShifts: ShiftType[] = querySnapshot.docs.map((doc) => {
                    let localDocObject = doc.data();
                    convertPropertyToDate(localDocObject, 'ShiftDate')
                    convertPropertyToDate(localDocObject, 'StartTime')
                    convertPropertyToDate(localDocObject, 'EndTime')
                    convertPropertyToDate(localDocObject, 'ClockIn')
                    convertPropertyToDate(localDocObject, 'ClockOut')
                    return localDocObject as ShiftType
                })
                setShifts(AllShifts)

            })
        return () => unsubscribe()
    }, []);
    return (
        <ShiftsScheduleDisplay shifts={Shifts} />
    )
}

export default JobShiftsDisplay
