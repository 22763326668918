import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#BD1E2C",
    },
    secondary: {
      light: "#dbdcdd",
      main: "#131A21",
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: 18
  }
});