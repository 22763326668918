import { ShiftType } from "../Components/Types";
import firebase from "../firebase";

let weekDays = [
    "Sun",
    "Mon",
    "Tues",
    "Wed",
    "Thur",
    "Fri",
    "Sat"
]

export function getLocalDateTime(dateTime: string) {
    const dateTimeObj = new Date(dateTime)
    return new Date(dateTimeObj.getTime() - (dateTimeObj.getTimezoneOffset() * 60000)).toISOString().slice(0, -5)
}

export function getNumDays(startDate: Date, endDate: Date) {
    return ((endDate.getDate() - startDate.getDate()) ) + 1
}

export function getWeekDay(date: Date) {
    return weekDays[date.getDay()]
}

export function buildDateHeader(startDate: Date, daysToAdd: number) {
    let newDate: Date = startDate;
    newDate.setDate(startDate.getDate() + daysToAdd);
    return getWeekDay(newDate) + " " + (newDate.getMonth() + 1).toString() + " - " + newDate.getDate().toString();
}

export function buildReportHeaderDate(date: Date): string {
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
}

export function buildWeekStart(date: Date): Date {
    let newDate: Date = new Date(date)
    newDate.setDate(date.getDate() - date.getDay());
    newDate.setHours(0, 0, 0, 0);
    return newDate;
}

export function buildWeekEnd(date: Date): Date {
    let newDate: Date = new Date(date)
    newDate.setDate(date.getDate() + (6 - date.getDay()))
    newDate.setHours(0, 0, 0, 0);
    return newDate;
}

export function convertPropertyToDate(object, propertyName) {
    object[propertyName] = (object[propertyName] as firebase.firestore.Timestamp).toDate()
}

export function cleanUpShiftPeriodsWorked(shift: ShiftType) {
    shift.TimePeriodsWorked.forEach(periodWorked => {
        periodWorked.clockIn && convertPropertyToDate(periodWorked, 'clockIn');
        periodWorked.clockOut && convertPropertyToDate(periodWorked, 'clockOut')
    })
}

export function cleanUpShiftForExport(shift: ShiftType) {
    convertPropertyToDate(shift, 'ShiftDate');
    convertPropertyToDate(shift, 'ClockIn');
    convertPropertyToDate(shift, 'ClockOut');
    convertPropertyToDate(shift, 'StartTime');
    convertPropertyToDate(shift, 'EndTime');

    shift.ExportDate = shift.ShiftDate.toLocaleDateString();
    shift.ExportIn = shift.ClockIn.toLocaleString('en-US', { hour:'numeric',minute:'numeric', hour12: true });
    shift.ExportOut = shift.ClockOut.toLocaleString('en-US', { hour:'numeric',minute:'numeric', hour12: true });
    shift.ExportStart = shift.StartTime.toLocaleString('en-US', { hour:'numeric',minute:'numeric', hour12: true });
    shift.ExportEnd = shift.EndTime.toLocaleString('en-US', { hour:'numeric',minute:'numeric', hour12: true });
    shift.ExportHoursWorked = shift.HoursWorked.toFixed(2);
    shift.ExportShiftTotal = shift.ShiftTotal.toFixed(2);
}

export function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, "");

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early

    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
    )}-${phoneNumber.slice(6, 10)}`;
}

export function leftJoin(objArr1: any[], objArr2: any[], key1: any, key2: any) {
    objArr1.map(
        anObj1 => ({
            ...objArr2.find(
                anObj2 => anObj1[key1] === anObj2[key2]
            ),
            ...anObj1
        })
    )
};