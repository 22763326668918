import { useEffect, useState } from "react";
import { Button, ButtonGroup } from "@material-ui/core";
import styled from "@emotion/styled";
import { DeleteOutlineOutlined, AddOutlined } from '@material-ui/icons';
import { ShiftType, PositionType, JobType } from "../../Types";
import {firestore} from "../../../firebase";
import { cleanUpShiftPeriodsWorked, convertPropertyToDate } from "../../../Scripts/utils";
import { disableShift, addShift } from "../../../Scripts/myFirebase";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import Shifts from "./Shifts";

interface ShiftsContainerProps {
  Job: JobType,
  Position: PositionType,
  ShiftDate: Date
}

export default function ShiftsContainer(props: ShiftsContainerProps) {
  const [localShifts, setLocalShifts] = useState<ShiftType[]>([]);

  useEffect(() => {
    const unsubscribe = firestore
      .collection("Shifts")
      .where("PositionID", "==", props.Position.Id)
      .where("ShiftDate", "==", props.ShiftDate)
      .where("isActive", "==", true)
      .onSnapshot((querySnapshot) => {
        let x: ShiftType[] = querySnapshot.docs.map((doc) => {
          let localDocObject = doc.data();
          convertPropertyToDate(localDocObject, 'ShiftDate')
          convertPropertyToDate(localDocObject, 'StartTime')
          convertPropertyToDate(localDocObject, 'EndTime')
          convertPropertyToDate(localDocObject, 'ClockIn')
          convertPropertyToDate(localDocObject, 'ClockOut')
          cleanUpShiftPeriodsWorked(localDocObject as ShiftType);
          return localDocObject as ShiftType;
        })
        setLocalShifts(x);
      })
    return () => unsubscribe()
  }, []);

  function handleDelete(shiftID: string): void {
    disableShift(shiftID);
  }

  function handleAdd(): void {
    addShift({
      ID: uuidv4(),
      PositionID: props.Position.Id,
      JobID: props.Job.Id,
      EmployeeUID: "",
      EmployeeName: "",
      EmployeeFirstName: "",
      EmployeeLastName: "",
      EmployeePhone: "",
      ClientName: props.Job.ClientName,
      JobName: props.Job.JobName,
      PositionTitle: props.Position.Title,
      ShiftDate: props.ShiftDate,
      ShiftDay: moment(props.ShiftDate).format('ddd'),
      StartTime: props.ShiftDate,
      EndTime: props.ShiftDate,
      ShiftTotal: 0,
      Description: props.Job.JobDescription,
      Location: props.Position.ReportLocation,
      Uniform: props.Position.Uniform,
      Status: "New",
      ClockIn: props.ShiftDate,
      ClockOut: props.ShiftDate,
      HoursWorked: 0,
      PayRate: props.Position.PayRate,
      SpecialNotes: props.Position.SpecialNotes,
      isActive: true,
      TimePeriodsWorked: []
    })
  }

  if (localShifts.length > 0) {
    return (
      <>
        {localShifts.map((shift: ShiftType, index) => {
          return (
            <ShiftContainer
              key={shift.ID}
            >
              <Shifts Shift={shift} />
              <ButtonGroup>
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<DeleteOutlineOutlined />}
                  color={"primary"}
                  onClick={() => {
                    handleDelete(shift.ID);
                  }}
                >
                  DEL
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<AddOutlined />}
                  color={"secondary"}
                  onClick={handleAdd}
                >
                  ADD
                </Button>
              </ButtonGroup>
            </ShiftContainer>
          )
        })}
      </>
    );
  } else {
    return (
      <Button
        size="small"
        variant="outlined"
        startIcon={<AddOutlined />}
        color={"secondary"}
        onClick={handleAdd}
      >
        ADD
      </Button>
    )
  }


}

const ShiftContainer = styled.div({
  display: 'flex',
  gap: '.5rem',
  fontSize: 16,
  flexDirection: "column"
})
