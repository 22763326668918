import { PeriodWorkedType } from "../../Types";
import styled from "@emotion/styled";
import TimePeriod from "./TimePeriod";

interface TimePeriodsProps {
    TimePeriodsWorked: PeriodWorkedType[]
  }

const TimePeriods = (props: TimePeriodsProps) => {
    if (props.TimePeriodsWorked && props.TimePeriodsWorked.length > 0) {
        return(
            <TimePeriodsDiv>
                {props.TimePeriodsWorked.map(TimePeriodObj => {
                    return <TimePeriod TimePeriodProp={TimePeriodObj} />
                })}
            </TimePeriodsDiv>
        )
    } else {
        return (
            <div>No shifts worked yet</div>
        )
    }
};

const TimePeriodsDiv = styled.div({
    display: 'flex',
    flexDirection: "column",
  })
  

export default TimePeriods;
