import { Button, SvgIconTypeMap } from '@material-ui/core';
import {firestore} from '../../../../firebase';
import MaterialTable from 'material-table';
import moment from 'moment';
import { FunctionComponent, useEffect, useState, useRef } from 'react';
import { JobType, MUITableColumn, PositionType, User, ShiftType } from '../../../Types';
import EmployeeSelector from './EmployeeSelector';
import JobShiftsDisplay from './JobShiftsDisplay';
import { createMuiTheme } from "@material-ui/core/styles";
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import { scheduleShifts, selectShiftsByPosition, unselectShiftsByPosition } from '../../../../Scripts/myFirebase';

interface JobPositionDisplayProps {
  job: JobType
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#BD1E2C",
    },
    secondary: {
      light: "#dbdcdd",
      main: "#BD1E2C",
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: 18
  }
});

const JobPositionDisplay: FunctionComponent<JobPositionDisplayProps> = (props: JobPositionDisplayProps) => {

  const [JobPositions, setJobPositions] = useState<PositionType[]>([]);
  const [SelectedEmployee, setSelectedEmployee] = useState<User>();
  const [shouldClear, setShouldClear] = useState<boolean>(false);

  const tableRef = useRef<any>();

  useEffect(() => {
    const unsubscribe = firestore
      .collection("Positions")
      .where("JobId", "==", props.job.Id)
      .where("isActive", "==", true)
      .onSnapshot((querySnapshot) => {
        let x: PositionType[] = querySnapshot.docs.map((doc) => {
          let localDocObject = doc.data();
          return localDocObject as PositionType
        })
        setJobPositions(x)
      })
    return () => unsubscribe()
  }, [])

  const buildColumns = (): MUITableColumn[] => {
    let columnArray: MUITableColumn[] = [{
      title: 'Position',
      field: 'Title',
      cellStyle: {
        padding: 5,
        textAlign: "center"
      },
      sorting: false
    }]
    for (let i = 0; i < props.job.DaysNeeded; i++) {
      const shiftDate = moment(props.job.StartDate).add(i, 'days')
      columnArray.push({
        title: shiftDate.format('ddd M-DD'),
        field: '',
        render: position => <JobShiftsDisplay ShiftDate={shiftDate.toDate()} position={position} />,
        cellStyle: {
          padding: 0
        },
        sorting: false
      })
    }
    return columnArray;
  }
  
  const clearSelection = () => {
    if(tableRef.current) {
      tableRef.current.onAllSelected(false)
    }
  }

  function handleInvite():void {
    scheduleShifts(props.job.Id, SelectedEmployee);
    clearSelection();
    setShouldClear(!shouldClear)
  }

  function handleSelectionChange(event, rowData) {
    if (rowData) {
      event.length? selectShiftsByPosition(rowData) : unselectShiftsByPosition(rowData);
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <div style={{
        paddingBottom: 15, 
        display: 'flex',
        gap: '2rem'
      }}>
        <EmployeeSelector setSelectedEmployee={setSelectedEmployee} shouldClear={shouldClear}/>
        <Button
          disabled={SelectedEmployee ? false : true}
          variant={"contained"}
          color={"primary"}
          onClick={handleInvite}
        >Invite</Button>
      </div>
      <MaterialTable
        tableRef={tableRef}
        title={props.job.JobName}
        data={JobPositions}
        columns={buildColumns()}
        options={{
          paging: false,
          rowStyle: (rowData) => ({
            backgroundColor: rowData.tableData.id % 2 ? "#EEE" : "#FFF",
          }),
          search: false,
          toolbar: false,
          selection: true,
          headerStyle: {
            padding: 5,
            textAlign: "center"
          },        
        }}
        onSelectionChange={(event, rowData) => {
          handleSelectionChange(event, rowData);
        }}
      />
    </MuiThemeProvider>);
}

export default JobPositionDisplay;