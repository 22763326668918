import { makeStyles } from "@material-ui/styles";
import { FunctionComponent } from "react";
import { ShiftType } from "../../../Types";
import ShiftChipWithFlyout from "./ShiftChips/ShiftChipWithFlyout";

interface ShiftsScheduleDisplayProps {
    shifts?: ShiftType[]
}

const ShiftsScheduleDisplay: FunctionComponent<ShiftsScheduleDisplayProps> = (props) => {
    const useStyles = makeStyles(() => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            gap: '.5rem',
            padding: '1rem'
        },
    }));

    const classes = useStyles();
    return (
        <div className={classes.root}>
            {
                props.shifts?.map(shift => { 

                    return (
                        <ShiftChipWithFlyout shift={shift} ></ShiftChipWithFlyout>
                    )
                })
            }
        </div>
    );
}

export default ShiftsScheduleDisplay;