import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FunctionComponent, useEffect, useState } from 'react';
import { User } from '../../../Types';
import {firestore} from '../../../../firebase';

interface EmployeeSelectorProps {
    setSelectedEmployee: any
    shouldClear: any
  }
  
  const EmployeeSelector: FunctionComponent<EmployeeSelectorProps> = (props: EmployeeSelectorProps) => {
  
    const [Employees, setEmployees] = useState<User[]>([])

    useEffect(() => {
        const unsubscribe = firestore
            .collection("Users")
            .where("isActive", "==", true)
            .onSnapshot((querySnapshot) => {
                let allEmployees: User[] = querySnapshot.docs.map((doc) => {
                    let localDocObject = doc.data();
                    localDocObject.label = localDocObject.Name;
                    return localDocObject as User
                })
                setEmployees(allEmployees)
            })
        return () => unsubscribe()
    }, []);

  return (
    <Autocomplete
      key={props.shouldClear}
      disablePortal
      id="employee-selector"
      onChange={(event, newValue: any | null)=> {props.setSelectedEmployee(newValue);}}
      options={Employees}
      sx={{ 
        width: 300,
        borderColor: "red"
       }}
      renderInput={(params) => <TextField {...params} label="Employee" />}
      ListboxProps={
        {
          style:{
              maxHeight: '250px',
          }
        }
      }
    />
  );
}


export default EmployeeSelector;