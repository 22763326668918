import ShiftCard from "../../Components/User/MyShifts/ShiftCard";
import { ShiftType } from '../../Components/Types';
import firebase, {firestore, auth} from '../../firebase';
import { useEffect, useState } from "react";
import { convertPropertyToDate } from "../../Scripts/utils";
import { CircularProgress } from "@material-ui/core";

const MyShifts = () => {

  const today = new Date();

  const [myShiftArray, setAllShifts] = useState<ShiftType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true)
    const unsubscribe = firestore
      .collection("Shifts")
      .where("EmployeeUID", "==", auth.currentUser.uid)
      .where("isActive", "==", true)
      .where("ShiftDate", ">=", firebase.firestore.Timestamp.fromDate(new Date(today.setDate(today.getDate() - 1))))
      .orderBy("ShiftDate")
      .onSnapshot((querySnapshot) => {
        setIsLoading(false)
        let x: ShiftType[] = querySnapshot.docs.map(function (doc) {
          let localDocObject = doc.data()
          convertPropertyToDate(localDocObject, 'ClockIn')
          convertPropertyToDate(localDocObject, 'ClockOut')
          convertPropertyToDate(localDocObject, 'EndTime')
          convertPropertyToDate(localDocObject, 'ShiftDate')
          convertPropertyToDate(localDocObject, 'StartTime')
          let shift = localDocObject as ShiftType;
          return shift;
        })
        setAllShifts(x)
      })
    return () => unsubscribe()
  }, [])

  return (
    <>
      <div style={{ display: "flex", gap: "2rem", flexWrap: "wrap", justifyContent: "center" }}>
        {myShiftArray.map(shift => {
          return <ShiftCard {...shift} />
        })
        }
      </div>
      {isLoading ?
        <CircularProgress />
        : null
      }
    </>
  )
}

export default MyShifts;
