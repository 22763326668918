import { useState } from "react";
import { TextField } from "@material-ui/core";
import styled from "@emotion/styled";
import { ShiftType } from "../../Types";
import moment from "moment";
import { updateShift } from "../../../Scripts/myFirebase";

interface ShiftsProps {
  Shift: ShiftType
}

export default function Shifts(props: ShiftsProps) {
  const [Shift, setShift] = useState<ShiftType>(props.Shift);

  const updateStartTime = (event) => {
      let newTime = Shift.StartTime;
      newTime.setHours(parseInt(event.target.value[0] + event.target.value[1]))
      newTime.setMinutes(parseInt(event.target.value[3] + event.target.value[4]))
      setShift({ ...Shift, StartTime: newTime});
      updateShift(Shift);
  }

  const updateEndTime = (event) => {
    let newTime = Shift.EndTime;
    newTime.setHours(parseInt(event.target.value[0] + event.target.value[1]))
    newTime.setMinutes(parseInt(event.target.value[3] + event.target.value[4]))
    console.log("NewTime: " + moment(newTime).format('LLL'));
    console.log("StartTime: " + moment(Shift.StartTime).format('LLL'));
    setShift({ ...Shift, EndTime: newTime, ShiftTotal: moment(newTime).diff(moment(Shift.StartTime), 'hours', true)})
    updateShift(Shift);
}

  return (
    <ShiftDiv>
      <TextField
        id="startTime"
        type="time"
        value={moment(Shift.StartTime).format('HH:mm')}
        onChange={updateStartTime}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 900, // 15 min
        }}
        style={{ fontSize: 14 }}
      />
      <TextField
        id="endTime"
        type="time"
        value={moment(Shift.EndTime).format('HH:mm')}
        onChange={updateEndTime}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 900, // 15 min
        }}
      />
    </ShiftDiv>
  );
}

const ShiftDiv = styled.div({
  display: 'flex',
  gap: '.5rem',
  fontSize: 16,
  flexDirection: "column",
})
