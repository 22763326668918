import { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { ShiftType } from "../../Types";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {buildReportHeaderDate, buildWeekEnd, buildWeekStart} from '../../../Scripts/utils';
import {firestore} from "../../../firebase";
import { CircularProgress } from "@material-ui/core";
import { cleanUpShiftForExport } from "../../../Scripts/utils";
import TimePeriods from "./TimePeriods";

const ReportsTable = () => {

  const today = new Date();

  //State management
  const [myShiftArray, setAllShifts] = useState<ShiftType[]>([])
  const [weekStart, setWeekStart] = useState<Date>(buildWeekStart(today))
  const [weekEnd, setWeekEnd] = useState<Date>(buildWeekEnd(today))
  const [isLoading,setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading( true)
      const unsubscribe = firestore
      .collection("Shifts")
      .where("isActive", "==", true)
      .onSnapshot((querySnapshot) => {
        let x: ShiftType[] = querySnapshot.docs.map(function (doc) {
          let localDocObject = doc.data()
          cleanUpShiftForExport(localDocObject as ShiftType)
          let shift =  localDocObject as ShiftType;
          return shift;
        })
        setAllShifts(x)
      })
      
      setIsLoading(false)
      return () => unsubscribe()
    },[])


  

  const handleNextWeek = () => {
    setWeekStart(new Date(weekStart.setDate(weekStart.getDate() + 7)))
    setWeekEnd(new Date(weekEnd.setDate(weekEnd.getDate() + 7)))
  }

  const handleLastWeek = () => {
    setWeekStart(new Date(weekStart.setDate(weekStart.getDate() - 7)))
    setWeekEnd(new Date(weekEnd.setDate(weekEnd.getDate() - 7)))
  }

  //User table data and columns
  
  const columns: { title: string; field: string , render?:any}[] = [
    { title: "Client", field: "ClientName" },
    { title: "Job", field: "JobName" },
    { title: "Day", field: "ShiftDay" },
    { title: "Date", field: "ExportDate"},
    { title: "Position", field: "PositionTitle" },
    { title: "First Name", field: "EmployeeFirstName" },
    { title: "Last Name", field: "EmployeeLastName" },
    { title: "Phone", field: "EmployeePhone" },
    { title: "Start Time", field: "ExportStart" },
    { title: "End Time", field: "ExportEnd" },
    { title: "Shift Total", field: "ExportShiftTotal" },
    { title: "Hours", field: "ExportHoursWorked" },
    { title: "Pay Rate", field: "PayRate" },
  ];

  return (
    <>
      <MaterialTable
        title={`${buildReportHeaderDate(weekStart)} - ${buildReportHeaderDate(weekEnd)}`}
        columns={columns}
        data={myShiftArray.filter(shift => shift.ShiftDate <= weekEnd && shift.ShiftDate >= weekStart)}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        detailPanel={rowData => {return <TimePeriods TimePeriodsWorked={rowData.TimePeriodsWorked} />}}
        options={{
          paging: false,
          maxBodyHeight: "Calc(100VH - 225px)",
          rowStyle: (rowData) => ({
            backgroundColor: rowData.tableData.id % 2 ? "#EEE" : "#FFF",
          }),
          exportButton: true
        }}
        actions={[
          {
            icon: ArrowBackIcon,
            tooltip: "Last Week",
            isFreeAction: true,
            // position: "toolbar",
            onClick: () => {
            handleLastWeek();
            },
          },
          {
            icon: ArrowForwardIcon,
            tooltip: "Next Week",
            isFreeAction: true,
            // position: "toolbar",
            onClick: () => {
            handleNextWeek();
            },
          },
        ]}
      />
      {isLoading?
        <CircularProgress />
        :null
      }
    </>
  );
};

export default ReportsTable;
