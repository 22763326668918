import HADCOAppBar from "./HADCOAppBar";
import HADCOAppBarAuth from "./HADCOAppBarAuth";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../firebase";

function Header() {
  const [user] = useAuthState(auth);

  return (
    user ? <HADCOAppBarAuth/> : <HADCOAppBar/>
  );
}

export default Header;