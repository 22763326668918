import React, {Suspense} from 'react';
import Login from './Pages/Login';
import Tabs from './Pages/Tabs';
import Header from './Components/Header';
import {ThemeProvider} from '@material-ui/core/styles/';
import {theme} from '../src/Styles/theme'
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";

function App() {
  const [user] = useAuthState(auth);

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<div>Loading...</div>}>
        <Header />
        {user ? <Tabs/> : <Login/>}
      </Suspense>
      </ThemeProvider>
  );
}

export default App;
