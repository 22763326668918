import "date-fns";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { TextField, FormGroup } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { JobType } from "../../Types";

interface JobFormProps {
  job: JobType
}

const JobForm = (props: JobFormProps) => {
  const [payDate, setPayDate] = React.useState<Date | null>(
    props.job.PayDate ? new Date(props.job.PayDate) : new Date()
  );
  const [startDate, setStartDate] = React.useState<Date | null>(
    props.job.StartDate ? new Date(props.job.StartDate) : new Date()
  );
  const [endDate, setEndDate] = React.useState<Date | null>(
    props.job.EndDate ? new Date(props.job.EndDate) : new Date()
  );

  return (
    <FormGroup>
      <TextField
        id="clientName"
        label="Client Name"
        variant="outlined"
        color="primary"
        defaultValue={props.job.ClientName}
      />
      <TextField
        id="jobName"
        label="Job Name"
        variant="outlined"
        color="primary"
        style={{ marginTop: ".5rem" }}
        defaultValue={props.job.JobName}
      />
      <TextField
        id="uniform"
        label="Uniform"
        variant="outlined"
        multiline={true}
        color="primary"
        style={{ marginTop: ".5rem" }}
        defaultValue={props.job.Uniform}
      />
      <TextField
        id="reportLocation"
        label="Report Location"
        variant="outlined"
        color="primary"
        style={{ marginTop: ".5rem" }}
        defaultValue={props.job.ReportLocation}
      />
      {/* <TextField
        id="numberEmployees"
        label="Number of Employees"
        variant="outlined"
        type="number"
        color="primary"
        style={{ marginTop: ".5rem" }}
        defaultValue={props.job.NumberEmployees}
      /> */}
      <TextField
        id="jobDescription"
        label="Job Description"
        variant="outlined"
        multiline={true}
        color="primary"
        style={{ marginTop: ".5rem" }}
        defaultValue={props.job.JobDescription}
      />
      <TextField
        id="specialNotes"
        label="Special Notes"
        variant="outlined"
        multiline={true}
        color="primary"
        style={{ marginTop: ".5rem" }}
        defaultValue={props.job.SpecialNotes}
      />
      <TextField
        id="payRate"
        label="Pay Rate"
        variant="outlined"
        type="number"
        color="primary"
        style={{ marginTop: ".5rem" }}
        defaultValue={props.job.PayRate}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {/* @ts-ignore */}
        <KeyboardDatePicker
          margin="normal"
          id="payDate"
          label="Pay Date"
          format="MM/dd/yyyy"
          value={payDate}
          onChange={(event) => setPayDate(event)}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        {/* @ts-ignore */}
        <KeyboardDatePicker
          margin="normal"
          id="startDate"
          label="Start Date"
          format="MM/dd/yyyy"
          value={startDate}
          onChange={(event) => {
            setStartDate(event);
            setEndDate(event);
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />

        {/* @ts-ignore */}
        <KeyboardDatePicker
          margin="normal"
          id="endDate"
          label="End Date"
          format="MM/dd/yyyy"
          value={endDate}
          onChange={(event) => setEndDate(event)}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider>
    </FormGroup>
  );
};

export default JobForm;
