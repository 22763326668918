import firebase from "../firebase";
import {auth, firestore, functions} from "../firebase";
import { 
   JobType,
    PositionType,
    ShiftType,
    User
   } from '../Components/Types';
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import { convertPropertyToDate } from "./utils";

const updateEmail = functions.httpsCallable("updateEmail");
const createUserFunction = functions.httpsCallable("createUser");

export async function createUser(user: User) {
   createUserFunction(user)
   .then(something => {
      auth.sendPasswordResetEmail(user.Email);
   })
}

export function editUser(user: User, isEmailChange: boolean) {
   delete user.tableData;
   firestore.collection("Users").doc(user.UID).update(user)
   .catch(error => {
      return error;
   })
   if(isEmailChange) {
      updateEmail({UID: user.UID, Email: user.Email})
      .then(something => {
         auth.sendPasswordResetEmail(user.Email)
      })
   } 
}

export async function getJob(jobID: string):Promise< JobType | firebase.firestore.DocumentData | void> {
   return await firestore.collection("Jobs").doc(jobID).get()
   .then( (doc) => {
      if (doc.exists) {
         return doc.data();
      }
      else {
         console.log("Couldn't find that Job");
      }
   })
   .catch((error) => {
      console.log("Error getting Job", error)
   })  
}

export function updateJob(job: JobType):void {
      firestore.collection("Jobs").doc(job.Id).set(job, {merge:true})
      .then(() => {
      })
      .catch(error => {
         console.log(error);
      })
}

export function disableJob(id: string) {
   firestore.collection("Jobs").doc(id).update({
      IsActive: false
   })
}

export function addShift(shift:ShiftType):void {
   firestore.collection("Shifts").doc(shift.ID).set(shift);
}

export function shiftAcceptShift(shiftID : string):void {
   firestore.collection("Shifts").doc(shiftID).update({
      Status: "Accepted"
   })
}

export function shiftRejectShift(shiftID : string):void {
   firestore.collection("Shifts").doc(shiftID).update({
      Status: "Rejected",
      EmployeeUID: ""
   })
}

export function shiftClockIn(shift : ShiftType) {
   let tempTimePeriodsWorked = shift.TimePeriodsWorked;
   tempTimePeriodsWorked.push({
      clockIn: new Date(),
      clockOut: null
   })
   firestore.collection("Shifts").doc(shift.ID).update({
      Status: "In",      
      TimePeriodsWorked: tempTimePeriodsWorked
   })
}

export function shiftClockOut(shift : ShiftType) {
   shift.TimePeriodsWorked[shift.TimePeriodsWorked.length - 1].clockOut = new Date();
   convertPropertyToDate(shift.TimePeriodsWorked[shift.TimePeriodsWorked.length - 1], 'clockIn');
   firestore.collection("Shifts").doc(shift.ID).update({
      Status: "Out",
      TimePeriodsWorked: shift.TimePeriodsWorked,
      HoursWorked: shift.HoursWorked + parseFloat((
         (
            shift.TimePeriodsWorked[shift.TimePeriodsWorked.length - 1].clockOut.getTime() - 
            shift.TimePeriodsWorked[shift.TimePeriodsWorked.length - 1].clockIn.getTime()
         ) / 1000 / 60 / 60
      ).toFixed(2))
   })
}

export function shiftInviteEmployee(shiftID : string, employeeUID : string, employeeName : string):void {
   firestore.collection("Shifts").doc(shiftID).update({
      Status: "Pending",
      EmployeeUID: employeeUID, 
      EmployeeName: employeeName
   })
}

export function addPosition(job:JobType, positionTitle:string):void {
   const positionID:string = uuidv4();
   let today: Date = new Date();
   today.setHours(0, 0, 0);

   firestore.collection("Positions").doc(positionID).set({
      Id: positionID,
      JobId: job.Id,
      Title: positionTitle,
      StartDate: job.StartDate,
      PayRate: job.PayRate,
      ReportLocation: job.ReportLocation,
      SpecialNotes: job.SpecialNotes,
      Uniform: job.Uniform,
      Description: job.JobDescription,
      isActive: true,
   }as PositionType, 
      {merge:true}
      )

   for(let i = 0; i < job.DaysNeeded; i++) {
      const currentShiftDate = moment(job.StartDate).add(i, 'days').toDate();
      addShift({
         ID: uuidv4(),
         PositionID: positionID,
         JobID: job.Id,
         EmployeeUID: "",
         EmployeeName: "",
         EmployeeFirstName: "",
         EmployeeLastName: "",
         ClientName: job.ClientName,
         JobName: job.JobName,
         PositionTitle: positionTitle,
         ShiftDate: currentShiftDate,
         ShiftDay: moment(currentShiftDate).format('ddd'),
         StartTime: currentShiftDate,
         EndTime: currentShiftDate,
         ShiftTotal: 0,
         Description: job.JobDescription,
         Location: job.ReportLocation,
         Uniform: job.Uniform,
         Status: "New",
         ClockIn: currentShiftDate,
         ClockOut: currentShiftDate,
         HoursWorked: 0,
         PayRate: job.PayRate,
         SpecialNotes: job.SpecialNotes,
         isActive: true,
         TimePeriodsWorked: []
      }as ShiftType)
   }
}

export function disablePosition(positionID: string):void {
   firestore.collection("Positions").doc(positionID).update({
      isActive: false
   }).then(something => {
      disableShiftsbyPosition(positionID);
   });
}

export function updatePosition(position: PositionType) {
   firestore.collection("Positions").doc(position.Id).update({
      ...position
   }).then(something => {
      updateShiftsbyPosition(position);
   });
}

export async function getShiftsbyPosition(positionID: string):Promise< ShiftType[] | firebase.firestore.DocumentData | void> {
   return await firestore.collection("Shifts")
   .where("PositionID", "==", positionID)
   .where("isActive", "==", true)
   .get()
   .then((querySnapshot) => {
      let shiftArray: ShiftType[] = [];
     querySnapshot.forEach((doc) => {
          shiftArray.push(doc.data() as ShiftType)
      });
      return shiftArray;
  })
  .catch((error) => {
      console.log("Error getting documents: ", error);
  });
}

export function disableShift(shiftID: string):void {
   firestore.collection("Shifts").doc(shiftID).update({
      isActive: false
   });
}

export function updateShift(shift: ShiftType):void {
   firestore.collection("Shifts").doc(shift.ID).update({
      ...shift,
      ShiftTotal: moment(shift.EndTime).diff(moment(shift.StartTime), 'hours', true)
   });
}

export function disableShiftsbyPosition(positionID: string): void {
   getShiftsbyPosition(positionID)
   .then((shiftArray: ShiftType []) => {
      shiftArray.forEach((shift: ShiftType) => {
         disableShift(shift.ID);
      })
   })
}

export function updateShiftsbyPosition(position: PositionType): void {
   getShiftsbyPosition(position.Id)
   .then((shiftArray: ShiftType []) => {
      shiftArray.forEach((shift: ShiftType) => {
         updateShift({
            ...shift,
            PositionTitle: position.Title,
            PayRate: position.PayRate,
            Location: position.ReportLocation,
            Uniform: position.Uniform
         });
      })
   })
}

export function selectShiftsByPosition(position: PositionType): void {
   getShiftsbyPosition(position.Id)
   .then((shiftArray: ShiftType[]) => {
      shiftArray.forEach((shift: ShiftType) => {
         if (shift.Status === "New") {
            selectShift(shift.ID);
         }
      })
   })
}

export function unselectShiftsByPosition(position: PositionType): void {
   getShiftsbyPosition(position.Id)
   .then((shiftArray: ShiftType[]) => {
      shiftArray.forEach((shift: ShiftType) => {
         if (shift.Status === "Selected") {
            unSelectShift(shift.ID);
         }
      })
   })
}

export function selectShift(shiftID: string) : void {
   firestore.collection("Shifts").doc(shiftID).update({
      Status: "Selected",
      EmployeeName: "",
      EmployeeFirstName: "",
      EmployeeLastName: "",
      EmployeePhone: ""
   })
}

export function unSelectShift(shiftID: string) : void {
   firestore.collection("Shifts").doc(shiftID).update({
      Status: "New"
   })
}

export function scheduleShifts(JobID: string, user:User) : void {
   firestore.collection("Shifts")
   .where("JobID", "==", JobID)
   .where("Status", "==", "Selected")
   .get()
   .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
         doc.ref.update({
            Status: "Pending",
            EmployeeUID: user.UID,
            EmployeeName: user.Name,
            EmployeeFirstName: user.FirstName,
            EmployeeLastName: user.LastName,
            EmployeePhone: user.Phone
         })
      })
   })
}