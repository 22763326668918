import firebase from "firebase/app";

import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";

if (firebase.apps.length === 0) {
  firebase.initializeApp({
    apiKey: "AIzaSyDSdcmiuKJtzNSJ3ATyTkM9PMbaPoYP0X4",
    authDomain: "employeeapp-722ce.firebaseapp.com",
    projectId: "employeeapp-722ce",
    storageBucket: "employeeapp-722ce.appspot.com",
    messagingSenderId: "830741769430",
    appId: "1:830741769430:web:c50bfb9d257d29ef616d77",
    measurementId: "G-J7MDM8PM8Q"
  });
}

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.functions();

if (window.location.hostname.includes("localhost")) {
  auth.useEmulator("http://localhost:5004")
  firestore.useEmulator("localhost", 5001)
  functions.useEmulator("localhost", 5002)
}

export default firebase;