import { ReactNode } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

type ConfirmModalProps = {
  title: string;
  description: string;
  open: boolean;
  children: ReactNode;
};
const ConfirmModal = ({
  title,
  description,
  open,
  children,
}: ConfirmModalProps) => {
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      {children}
    </Dialog>
  );
};

export default ConfirmModal;
